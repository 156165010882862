import './stylesheet.scss';

import React from 'react';
import lodashGet from 'lodash.get';
import { ntype } from 'configs/constants';
import videoTypes from 'components/admin/video/schema/videoTypes';
import BlockList from './list';
import BlockQuote from './quote';
import BlockStatement from './statement';
import BlockGallery from './gallery';
import BlockFlashcard from './flashcard';
import BlockChart from './chart';
import BlockTimeline from './timeline';
import BlockImage from './image';
import BlockExercise from './exercise';
import BlockText from './text';
import BlockAudio from './audio';
import BlockVideo from './video';
import BlockScorm from './scorm';
import BlockLink from './link';
import { isScormSco } from 'components/admin/scorm/scorm';
import ScrollAnimation from 'common/scroll-animation';
import {
  getBlockAnimation,
  getInlineStyle,
  getWrapperClass,
} from '../common/utils';
import ScoSplNested from '../index';
import { connect } from 'react-redux';
import { useProgressSaving } from './saveProgress';
import DownloadDocumentButton from 'components/learn/common/DownloadDocumentButton';
import BlockPdf from './pdf';
import MicrosoftOffice from 'components/learn/items/lecture/microsoft-office';
import BlockH5P from 'components/learn/items/sco-spl/block/h5p';

const BlockItem = (props) => {
  const {
    iid,
    nodes,
    course,
    syllabus,
    trackerProgress,
    dispatch,
    isPreview,
    isReview,
    shouldSelfSavingProgress = true,
  } = props;

  const item = props.item || lodashGet(nodes, iid) || {};
  const materials = lodashGet(item, 'download_materials');

  useProgressSaving(
    item.iid,
    lodashGet(course, 'iid'),
    nodes,
    trackerProgress,
    dispatch,
    shouldSelfSavingProgress,
  );

  let content;
  if (item.ntype == ntype.VIDEO) {
    switch (item.type) {
      case videoTypes.TYPE_LIST:
        content = <BlockList item={item} course={course} syllabus={syllabus} />;
        break;
      case videoTypes.TYPE_QUOTE:
        content = (
          <BlockQuote item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_GALLERY:
        content = (
          <BlockGallery item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_FLASHCARD:
        content = (
          <BlockFlashcard item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_STATEMENT:
        content = (
          <BlockStatement item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_CHART:
        content = (
          <BlockChart item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_TIMELINE:
        content = (
          <BlockTimeline item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_IMAGE:
        content = (
          <BlockImage item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_TEXT:
        content = <BlockText item={item} course={course} syllabus={syllabus} />;
        break;
      case videoTypes.TYPE_AUDIO:
        content = (
          <BlockAudio item={item} course={course} syllabus={syllabus} />
        );
        break;
      case videoTypes.TYPE_VIDEO:
        content = (
          <BlockVideo
            item={item}
            course={course}
            isPreview={isPreview}
            isReview={isReview}
            showInScoSpl
          />
        );
        break;
      case videoTypes.TYPE_H5P:
        content = (
          <BlockH5P
            item={item}
            course={course}
            isPreview={isPreview}
            isReview={isReview}
            showInScoSpl
          />
        );
        break;
      case videoTypes.TYPE_PDF:
        content = (
          <BlockPdf
            item={item}
            course={course}
            isPreview={isPreview}
            isReview={isReview}
          />
        );
        break;
      case videoTypes.TYPE_LINK:
        content = (
          <BlockLink
            item={item}
            course={course}
            isPreview={isPreview}
            isReview={isReview}
            showInScoSpl
          />
        );
        break;
      case videoTypes.TYPE_WORD:
      case videoTypes.TYPE_PPT:
      case videoTypes.TYPE_EXCEL: {
        content = (
          <MicrosoftOffice
            className="learn-content-border"
            node={item}
            lectureType={item.type}
            course={course}
            isPreview={isPreview}
            isReview={isReview}
          />
        );
        break;
      }
      default:
        content = null;
    }
  } else if (item.ntype == ntype.EXERCISE) {
    content = <BlockExercise {...props} />;
  } else if (item.ntype == ntype.SCO) {
    if (isScormSco(item)) {
      content = <BlockScorm item={item} course={course} showInScoSpl />;
    } else {
      content = <ScoSplNested {...props} scoIid={item.iid} />;
    }
  }

  const style = getInlineStyle(item);

  content = (
    <div style={style} className={getWrapperClass(item)}>
      <div className="row">
        <div className="col-md-12 block-item-content">{content}</div>

        {materials && materials.length && (
          <div className="col-md-12 m-t-15">
            <DownloadDocumentButton learnItem={item} />
          </div>
        )}
      </div>
    </div>
  );

  if (content) {
    const animateIn = getBlockAnimation('in', item);
    const animateOut = getBlockAnimation('out', item);

    if ((!animateIn && !animateOut) || props.notInScoSpl) {
      return (
        <div
          className="container-fluid overflow-auto"
          style={{
            scrollbarWidth: 'none',
          }}
        >
          {content}
        </div>
      );
    }

    return (
      <ScrollAnimation
        animateIn={animateIn}
        animateOut={animateOut}
        scrollableParentSelector="#learn-backboard-container"
      >
        <div className="container-fluid">{content}</div>
      </ScrollAnimation>
    );
  }

  return null;
};

const mapStateToProps = (state, props) => ({
  nodes: state.tree,
  trackerProgress: state.trackerProgress,
  isPreview: lodashGet(state, 'learn.isPreview') || props.isPreview,
  isReview: lodashGet(state, 'learn.isReview'),
});

export default connect(mapStateToProps)(BlockItem);
