import React from 'react';
import lodashGet from 'lodash.get';
import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import sagaNodeActions from 'actions/node/saga-creators';
import { connect } from 'react-redux';
import commentApiUrls from 'components/common/comment/endpoints';
import { t1 } from 'translate';
import { createSelector } from 'reselect';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import fetchData from 'components/common/fetchData';
import CommentDetail from '../comment-detail/ant-comment';
import AddComment from '../add-comment';
import withUserInfo from 'common/hoc/withUserInfo';
import ActionBar from '../action-bar';
import { commentTypes } from 'configs/constants/comment';
import { formValueSelector } from 'redux-form';

import './stylesheet.scss';

import Form from 'schema-form/Form';

const getCommentFilterFormId = () => 'comment-filter';

const commentFiterFormSchema = {
  schema: () => {
    return {
      get_comments_from_other_courses_with_same_syllabus: {
        type: 'checkbox',
        label: t1('get_comments_from_other_courses_with_same_syllabus'),
        style: {
          lineHeight: 1.5,
        },
      },
    };
  },
  ui: () => {
    return [
      {
        id: 'default',
        fields: ['get_comments_from_other_courses_with_same_syllabus'],
      },
    ];
  },
};

class SearchComments extends React.PureComponent {
  shouldComponentUpdate(nextProps) {
    return (
      !isEqual(
        nextProps.searchFormCommentsId,
        this.props.searchFormCommentsId,
      ) || !isEqual(nextProps.hiddenFields, this.props.hiddenFields)
    );
  }

  render() {
    const {
      searchFormCommentsId,
      hiddenFields,
      renderResultsComponent,
    } = this.props;

    return (
      <SearchWrapper
        showResult
        showSearchButton={false}
        alwaysShowResult
        formid={searchFormCommentsId}
        hiddenFields={hiddenFields}
        alternativeApi={commentApiUrls.get_collaborating_item_comments}
        renderResultsComponent={renderResultsComponent}
        paginationProps={{
          className: 'comment-pagination',
          showExtraControl: false,
        }}
        classPaginationWrapper="pagination-default"
        resetForm
        showResultsWhenSubmitSucceeded
        autoSearchWhenValuesChange={false}
      />
    );
  }
}

const getParamsToQueryComments = ({
  collaboratingItem,
  isLearn,
  courseIid,
  syllabusIid,
  allowAskTeacher,
  getCommentsFromOtherCoursesWithSameSyllabus,
}) => {
  return {
    course_iid: courseIid,
    syllabus_iid: syllabusIid,
    collaborating_item_iid: collaboratingItem && collaboratingItem.iid,
    is_learn: isLearn ? 1 : 0,
    type: commentTypes.COMMENT_COLLABORATING_ITEM,
    ask_teacher:
      typeof allowAskTeacher !== 'undefined'
        ? allowAskTeacher
          ? 1
          : 0
        : undefined,
    get_comments_from_other_courses_with_same_syllabus: getCommentsFromOtherCoursesWithSameSyllabus,
  };
};

const getParamsToSendComment = ({
  comment,
  collaboratingItem = {},
  syllabusIid,
  courseIid,
  linkToItem,
  isLearn,
  allowAskTeacher,
}) => {
  const hasComment = comment && comment.id;

  return {
    comment: {
      collaborating_item: {
        iid: collaboratingItem.iid,
        id: collaboratingItem.id,
        name: collaboratingItem.name,
      },
      syllabus_iid: syllabusIid,
      course_iid: courseIid,
      link_to_item: linkToItem,
      is_learn: isLearn ? 1 : 0,
    },
    reply_comment_item: hasComment ? comment.id : undefined,
    type: commentTypes.COMMENT_COLLABORATING_ITEM,
    syllabus_iid: syllabusIid,
    course_iid: courseIid,
    step: commentTypes.COMMENT_COLLABORATING_ITEM,
    ask_teacher_to_comment: allowAskTeacher && !hasComment ? 1 : undefined,
  };
};

const ItemComments = ({
  title,
  collaboratingItem,
  isLearn,
  // count_comments,
  userInfo,
  displayInAdmin,
  onSendCommentSuccessfully,
  handleRefetch,
  syllabusIid,
  courseIid,
  linkToItem,
  queryParams,
  onResolvedCommentSuccessfully,
  dispatch,
  allowAskTeacher,
  showCheckboxToCommentForCourse,
  simplifyComment,
  getCommentsFromOtherCoursesWithSameSyllabus,
}) => {
  const defaultReplyCommentItem = lodashGet(queryParams, 'reply_comment_item');

  const [showDetailComments, setShowDetailComments] = React.useState(true); // !!defaultReplyCommentItem,
  const [reply_item, set_reply_item] = React.useState({});
  const [comments, setComments] = React.useState([]);
  const [replyCommentItem, setReplyCommentItem] = React.useState(
    defaultReplyCommentItem,
  );

  const collaboratingItemId = lodashGet(collaboratingItem, 'id');

  const searchFormCommentsId = `comment_search_${collaboratingItemId}_${
    allowAskTeacher ? 'ask_teacher' : ''
  }`;

  const handleExpandComment = () => {
    setShowDetailComments((v) => !v);
  };

  const onSendCommentSuccess = () => {
    if (typeof handleRefetch === 'function') {
      handleRefetch();
    }
    if (onSendCommentSuccessfully) {
      onSendCommentSuccessfully(collaboratingItem);
    }

    setShowDetailComments(true);
  };

  const getFormId = (comment, allowAskTeacher) =>
    comment && comment.id
      ? `reply_comment_${comment.id}`
      : allowAskTeacher
      ? `ask_teacher_${collaboratingItemId}`
      : `add_comment_${collaboratingItemId}`;

  const renderAddComment = ({
    comment = null,
    userToReply = null,
    showCheckboxCommentCourse,
  }) => {
    const formid = getFormId(comment, allowAskTeacher);

    return (
      <AddComment
        user={userInfo}
        searchFormId={searchFormCommentsId}
        formid={formid}
        key={formid}
        displayInAdmin={displayInAdmin}
        paramsToSendComment={getParamsToSendComment({
          comment,
          collaboratingItem,
          syllabusIid,
          courseIid,
          linkToItem,
          isLearn,
          allowAskTeacher,
        })}
        onSendCommentSuccess={onSendCommentSuccess}
        userToReply={userToReply}
        showCheckboxToCommentForCourse={showCheckboxCommentCourse}
        simplifyComment={simplifyComment}
      />
    );
  };

  const handleResolveCommentSuccess = () => {
    if (typeof handleRefetch === 'function') {
      handleRefetch();
    }

    if (onResolvedCommentSuccessfully) {
      onResolvedCommentSuccessfully(collaboratingItem);
    }
  };

  const handleResolveComment = (item) => {
    const params = {
      _sand_step: 'resolved_comment_collaborating_item',
      id: item.id,
      iid: item.iid,
      syllabus_iid: syllabusIid,
      comment: { ...item.comment, is_resolved: 1 },
    };
    dispatch(
      sagaNodeActions.getDataRequest(
        {
          url: commentApiUrls.resolve_comment,
          post: 1,
          executeOnSuccess: handleResolveCommentSuccess,
          successMessage: t1('comment_is_resolved'),
          failureMessage: t1('you_do_not_have_permission_to_resolve'),
        },
        params,
      ),
    );
  };

  const renderComments = () => {
    if (!Array.isArray(comments) || lodashGet(comments, 'length') <= 0) {
      return null;
    }

    return comments.map((item, index) => (
      <CommentDetail
        canResolveComment={!isLearn}
        replyCommentItem={lodashGet(queryParams, 'reply_comment_item')}
        key={item.iid || index}
        displayInAdmin={displayInAdmin}
        onResolveComment={handleResolveComment}
        searchFormCommentsId={searchFormCommentsId}
        item={item}
        elementReplyComment={
          lodashGet(reply_item, 'id') === item.id &&
          lodashGet(reply_item, 'show')
            ? renderAddComment({
                comment: item,
                userToReply: lodashGet(item, 'user.name'),
                showCheckboxCommentCourse: false,
              })
            : null
        }
        handleShowReplyComment={() => {
          set_reply_item((reply_item) => {
            if (lodashGet(reply_item, 'id') !== item.id) {
              return {
                id: item.id,
                show: true,
              };
            }

            return {};
          });
        }}
        allowAskTeacher={allowAskTeacher}
      />
    ));
  };
  const renderResultsComponent = (
    newComments = [],
    { formValues, onPageChange },
  ) => {
    let newReplyCommentItem = replyCommentItem;

    if (isEqual(newComments, comments)) {
      return;
    }

    let page = null;
    let comment = null;
    if (newReplyCommentItem) {
      if (Array.isArray(newComments) && newComments.length) {
        comment = newComments.find(({ id }) => id === newReplyCommentItem);
        if (!comment) {
          page = ((formValues && formValues.page) || 1) + 1;
        } else {
          page = formValues.page;
          newReplyCommentItem = null;
        }
      } else if (formValues && formValues.page > 1) {
        newReplyCommentItem = null;
        page = 1;
      }
    }

    setComments(Array.isArray(newComments) ? newComments : []);
    setReplyCommentItem(newReplyCommentItem);

    //TODO: make sure this is called after setComments and setReplyCommentItem has been call successfully
    if (page && !comment) {
      onPageChange(page, 10);
    }
  };

  const renderReplies = () => {
    // if (!count_comments) {
    //   return null;
    // }

    return (
      <>
        {renderComments()}
        <SearchComments
          searchFormCommentsId={searchFormCommentsId}
          hiddenFields={getParamsToQueryComments({
            collaboratingItem,
            isLearn,
            courseIid,
            allowAskTeacher,
            getCommentsFromOtherCoursesWithSameSyllabus,
          })}
          renderResultsComponent={renderResultsComponent}
        />
      </>
    );
  };

  return (
    <div
      className={`comment-component ${
        displayInAdmin ? 'comment-component--in-admin' : ''
      }`}
    >
      <Form
        formid={getCommentFilterFormId()}
        schema={commentFiterFormSchema}
        hideSubmitButton
      />

      {/* do not display comment count because of performance issue */}
      {/*<ActionBar*/}
      {/*  title={title}*/}
      {/*  label={*/}
      {/*    isLearn ? t1('student_comments_on') : t1('collaborators_comments_on')*/}
      {/*  }*/}
      {/*  handleExpandComment={handleExpandComment}*/}
      {/*  item={collaboratingItem}*/}
      {/*  totalComment={count_comments || 0}*/}
      {/*  showDetailComments={showDetailComments}*/}
      {/*  allowAskTeacher={allowAskTeacher}*/}
      {/*/>*/}

      {renderAddComment({
        showCheckboxCommentCourse: showCheckboxToCommentForCourse,
      })}

      <div
        className={`comments ${
          showDetailComments ? 'show-detail-comments' : 'hidden-detail-comments'
        }`}
      >
        {renderReplies()}
      </div>
    </div>
  );
};

const getCollaboratingItem = (collaboratingItemId, collaboratingItem = {}) => {
  if (collaboratingItemId === collaboratingItem && collaboratingItem.id)
    return collaboratingItem;

  const children = collaboratingItem.children;
  if (!children) return collaboratingItem;

  const obj = children.find((item) => item.id === collaboratingItemId);
  return obj && obj.iid ? obj : collaboratingItem;
};

const mapStateToProps = createSelector(
  (state, props) => props.collaboratingItemId,
  (state, props) => props.collaboratingItem,
  (state, props) => props.syllabusIid,
  (state, props) => {
    const queryParams = queryString.parse(window.location.search);
    const newProps = {
      queryParams,
    };
    if (queryParams.reply_comment_item) {
      newProps.linkToItem = window.location.pathname;
    }
    return newProps;
  },
  (state, props) => state.comment,
  (state, props) => {
    return formValueSelector(getCommentFilterFormId())(
      state,
      'get_comments_from_other_courses_with_same_syllabus',
    );
  },
  (
    collaboratingItemId,
    collaboratingItem,
    syllabusIid,
    newProps,
    comment,
    getCommentsFromOtherCoursesWithSameSyllabus,
  ) => ({
    ...newProps,
    collaboratingItemId,
    collaboratingItem: getCollaboratingItem(
      collaboratingItemId,
      collaboratingItem,
    ),
    comment,
    getCommentsFromOtherCoursesWithSameSyllabus,
  }),
);

const shouldRefetchCondition = (prevProps, props) => {
  return (
    lodashGet(prevProps, 'collaboratingItem.iid') !==
      lodashGet(props, 'collaboratingItem.iid') ||
    lodashGet(prevProps, 'comment.numberCommentOfSession') !==
      lodashGet(props, 'comment.numberCommentOfSession') ||
    lodashGet(prevProps, 'getCommentsFromOtherCoursesWithSameSyllabus') !==
      lodashGet(props, 'getCommentsFromOtherCoursesWithSameSyllabus')
  );
};

// export default connect(mapStateToProps)(
//   fetchData(
//     ({
//       collaboratingItem,
//       isLearn,
//       comment,
//       courseIid,
//       syllabusIid,
//       allowAskTeacher,
//       getCommentsFromOtherCoursesWithSameSyllabus,
//     }) => ({
//       baseUrl: commentApiUrls.count_collaborating_item_comments,
//       params: getParamsToQueryComments({
//         collaboratingItem,
//         isLearn,
//         courseIid,
//         syllabusIid,
//         allowAskTeacher,
//         getCommentsFromOtherCoursesWithSameSyllabus,
//       }),
//       propKey: 'count_comments',
//       fetchCondition: true,
//       refetchCondition: (prevProps) =>
//         shouldRefetchCondition(prevProps, {
//           collaboratingItem,
//           comment,
//           getCommentsFromOtherCoursesWithSameSyllabus,
//         }),
//       // Never refetch, I did not add this logic here, I just refactor based on the previous coder logic
//       // he/she did not pass refetchCondition here, therefore, it will never refetch
//       // I just refactor make it clearer
//     }),
//   )(withUserInfo(ItemComments)),
// );

export default connect(mapStateToProps)(withUserInfo(ItemComments));
